import { Button, Typography } from "@components/index";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Icon, { IconNames } from "./Icon";
import * as styles from "./Institution.module.scss";

const whatIsIncluded: { icon: IconNames; title: string }[] = [
	{
		icon: "people",
		title: "20 users",
	},
	{
		icon: "consumables",
		title: "Consumable",
	},
	{
		icon: "advanced-search",
		title: "Advanced search",
	},
	{
		icon: "revision-history",
		title: "Version control",
	},

	{
		icon: "freezer",
		title: "Inventory",
	},
	{
		icon: "protocol-library",
		title: "Protocol library",
	},
	{
		icon: "stock-tracker",
		title: "Stock tracker",
	},
	{
		icon: "order-management",
		title: "Orders",
	},

	{
		icon: "notebook",
		title: "Notebook",
	},
	{
		icon: "edit",
		title: "Custom item types",
	},
];

const whoIsEligible = [
	"Clinical research unit",
	"Nonprofits",
	"Core facilities",
	"Non-governmental orgs (NGOs)",
];

const Institution = () => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.institutionCard}>
				<div className={styles.title}>
					<Typography
						color="text-dark"
						variant="HEADER2"
						style={{ fontWeight: "bold" }}
					>
						Institution Pro
					</Typography>
					<Typography variant="SUBHEADERDESC">
						Best value for research teams at clinical units and
						nonprofit institutions
					</Typography>
					<div className={styles.buttons}>
						<Button
							className={styles.button}
							link="APPLICATION_INSTITUTION"
							target="_blank"
						>
							Apply now
						</Button>
						<Button
							className={styles.signUpBtn}
							link="SIGN_UP"
							target="_blank"
							type="ghost"
						>
							Sign up free
						</Button>
					</div>
				</div>
				<div className={styles.info}>
					<div className={styles.container}>
						<Typography color="text-dark" variant="SUBTITLE">
							Who is eligible:
						</Typography>
						<div className={styles.whoIsElegibleRows}>
							{whoIsEligible.map((item, index) => (
								<div key={index} className={styles.item}>
									<svg
										id="pricing_checkmark"
										data-name="pricing checkmark"
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 32 32"
									>
										<rect
											id="Rectangle_9961"
											data-name="Rectangle 9961"
											width="32"
											height="32"
											fill="rgba(255,255,255,0)"
										/>
										<path
											id="Icon_metro-checkmark"
											data-name="Icon metro-checkmark"
											d="M17.758,5.784,9.321,14.222,5.383,10.284,2.571,13.1l6.75,6.75L20.571,8.6Z"
											transform="translate(4.93 2.216)"
											fill="#000"
										/>
									</svg>
									<Typography color="text-dark">
										{item}
									</Typography>
								</div>
							))}
						</div>
					</div>{" "}
					<div className={styles.container}>
						<Typography color="text-dark" variant="SUBTITLE">
							What is included:
						</Typography>
						<div className={styles.whatIsIncludedRows}>
							{whatIsIncluded.map(({ icon, title }, index) => (
								<div key={index} className={styles.item}>
									<Icon name={icon} />
									<Typography color="text-dark">
										{title}
									</Typography>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Institution;
