// extracted by mini-css-extract-plugin
export var button = "Institution-module--button--KnTq1";
export var buttons = "Institution-module--buttons--vYm1T";
export var info = "Institution-module--info--X+x8K";
export var institutionCard = "Institution-module--institutionCard--Credv";
export var item = "Institution-module--item--wgJ45";
export var limitWidthOnLargeScreens = "Institution-module--limitWidthOnLargeScreens--pTuFS";
export var signUpBtn = "Institution-module--signUpBtn--+2J-4";
export var title = "Institution-module--title--sAFO-";
export var whatIsIncludedRows = "Institution-module--whatIsIncludedRows--Kxzu3";
export var whoIsElegibleRows = "Institution-module--whoIsElegibleRows--sTKDJ";